/* eslint-disable react/function-component-definition */
import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompaniesStatus, CompanyStatus, ICompaniesDetail } from 'interfaces/companies';
import { getCompaniesByIdApi, patchCompanyApi } from 'api';
import styles from './CompaniesRespond.module.scss';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import RespondAbout from '../components/RespondAbout';
import UsersInformation from '../components/UsersInformation';
import AdditionalInformation from '../components/AdditionalInformation';

const CompaniesRespond: React.FC = () => {
  const { slug } = useParams();
  const [value, setValue] = useState('');
  const [object, setObject] = useState({} as ICompaniesDetail);

  const getCompany = async () => {
    try {
      if (slug) {
        const res = await getCompaniesByIdApi(slug);
        setObject(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const onHandler = (status: CompanyStatus) => {
    patchCompanyApi({ ...object, status }).then(() => {
      setObject({ ...object, status });
    });
  };

  return (
    <>
      <Typography fontSize={20} fontWeight={600} sx={{ color: '#343A40', marginBottom: '24px' }}>
        Request #{slug}
      </Typography>
      <RespondAbout item={object} />
      <UsersInformation item={object} />
      <AdditionalInformation />
      {object.status === CompaniesStatus.NotReviewed && (
        <BackgroundCard>
          <Typography className={styles.title}>Approval</Typography>
          <Button
            variant="contained"
            sx={{
              marginTop: '20px',
              padding: '12px 26px',
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: '16px',
              marginLeft: 'auto',
              backgroundColor: 'var(--color-primary-yellow)'
            }}
            onClick={() => onHandler(CompaniesStatus.Approved)}
          >
            Approve the Request
          </Button>
          <Button
            variant="outlined"
            sx={{
              marginTop: '20px',
              padding: '12px 20px',
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: '16px',
              marginLeft: '16px',
              width: '120px'
            }}
            onClick={() => onHandler(CompaniesStatus.Rejected)}
          >
            reject
          </Button>
        </BackgroundCard>
      )}
    </>
  );
};

export default CompaniesRespond;
