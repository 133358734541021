/* eslint-disable react/function-component-definition */
import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { CompaniesStatus, ICompaniesDetail } from 'interfaces/companies';
import { getUserData } from 'utils/getUserData.utils';
import { formatDate } from 'utils/helper';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import styles from '../companies-respond/CompaniesRespond.module.scss';

interface IRespondAbout {
  item: ICompaniesDetail;
}

const RespondAbout: React.FC<IRespondAbout> = ({ item }) => {
  const { id } = useParams();
  const { showModal } = useGlobalModalContext();
  const [companies, setCompanies] = useState<ICompaniesDetail>({} as ICompaniesDetail);

  useEffect(() => {
    if (item) {
      setCompanies(item);
    }
  }, [item]);

  return (
    <BackgroundCard>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <>
          <Avatar
            src={getUserData(item.user, 'avatar_link', '')}
            sx={{ marginRight: '20px', width: '56px', height: '56px' }}
          />
          <Box>
            <Typography className={styles.aboutText} fontWeight={500} textTransform="capitalize">
              {getUserData(item.user, 'username', 'unnamed')}
            </Typography>
            <Typography className={styles.aboutText} color="#838383">
              {getUserData(item.user, 'email', 'not email')}
            </Typography>
          </Box>
        </>
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          status:
        </Typography>
        <Typography
          variant="body2"
          textTransform="capitalize"
          border="1px solid"
          borderRadius="100px"
          padding="4px 10px"
          color={
            companies.status === CompaniesStatus.Approved
              ? 'var(--color-green-recommended)'
              : 'var(--color-red-job-placement)'
          }
          className={styles.aboutText}
        >
          {companies.status}
        </Typography>
        {item.status !== CompaniesStatus.NotReviewed && (
          <Button
            sx={{ textTransform: 'capitalize', color: '#FFA425' }}
            onClick={() => {
              showModal(MODAL_TYPES.COMPANY_CHANGE_STATUS, {
                item: companies,
                setCompanies
              });
            }}
          >
            <BorderColorOutlinedIcon fontSize="small" />
            <Typography
              className={styles.aboutText}
              sx={{ borderBottom: '2px solid', lineHeight: '16px' }}
            >
              Change status
            </Typography>
          </Button>
        )}
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          company name:
        </Typography>
        <Typography color="#838383" className={styles.aboutText}>
          {companies.name}
        </Typography>
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          request date:
        </Typography>
        {item.created && (
          <Typography color="#838383" className={styles.aboutText}>
            {formatDate(item.created)}
          </Typography>
        )}
      </Box>
      <Box className={styles.element}>
        <Button
          color="secondary"
          sx={{
            border: '1px solid #838383',
            borderRadius: '8px',
            padding: '7px',
            minWidth: '38px'
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            showModal(MODAL_TYPES.JOB_PLACEMENT_DELETE_MODAL, {
              id,
              email: 'email'
            });
          }}
        >
          <DeleteOutlinedIcon />
        </Button>
      </Box>
    </BackgroundCard>
  );
};

export default RespondAbout;
