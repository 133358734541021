export type DataType = {
  id: number;
  avatar: string;
  name: string;
  email: string;
  status: string;
  category: string;
  date: string;
};

const jobPlacementData: DataType[] = [
  {
    id: 1,
    avatar: 'I',
    name: 'Ivan Drago',
    email: 'email1@mail.ru',
    status: 'unemployed',
    category: 'job category',
    date: '12.05.2022'
  },
  {
    id: 2,
    avatar: 'D',
    name: 'David Drago',
    email: 'email2@mail.ru',
    status: 'employed',
    category: 'job category',
    date: '18.06.2022'
  },
  {
    id: 3,
    avatar: 'S',
    name: 'Sasha Drago',
    email: 'email3@mail.ru',
    status: 'unemployed',
    category: 'job category',
    date: '17.04.2022'
  },
  {
    id: 4,
    avatar: 'A',
    name: 'Alex Drago',
    email: 'email4@mail.ru',
    status: 'unemployed',
    category: 'job category',
    date: '16.08.2022'
  },
  {
    id: 5,
    avatar: 'V',
    name: 'Vova Drago',
    email: 'email5@mail.ru',
    status: 'employed',
    category: 'job category',
    date: '12.02.2022'
  },
  {
    id: 6,
    avatar: 'T',
    name: 'Toma Drago',
    email: 'email6@mail.ru',
    status: 'employed',
    category: 'job category',
    date: '11.01.2022'
  },
  {
    id: 7,
    avatar: 'R',
    name: 'Renat Drago',
    email: 'email7@mail.ru',
    status: 'employed',
    category: 'job category',
    date: '15.05.2022'
  },
  {
    id: 8,
    avatar: 'P',
    name: 'Petr Drago',
    email: 'email8@mail.ru',
    status: 'employed',
    category: 'job category',
    date: '17.07.2022'
  }
];

export default jobPlacementData;
