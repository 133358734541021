import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';

import { IDAButton } from 'interfaces';
import { useNavigate } from 'react-router-dom';
import styles from './DAButton.module.scss';

function DAButton({
  text,
  variant,
  href,
  startIcon,
  endIcon,
  style,
  disabled = false,
  handleClick
}: IDAButton) {
  const navigate = useNavigate();

  return (
    <Button
      variant={variant}
      onClick={() => (href ? navigate(`${href}`) : handleClick && handleClick())}
      className={clsx(styles.defaultButton, {
        [styles.containedButton]: variant === 'contained',
        [styles.outlinedButton]: variant === 'outlined'
      })}
      startIcon={startIcon}
      endIcon={endIcon}
      style={style}
      disabled={disabled}
      classes={{ startIcon: styles.startIcon, endIcon: styles.endIcon }}
    >
      {text}
    </Button>
  );
}

export default DAButton;
