/* eslint-disable react/function-component-definition */
import { Button, Typography } from '@mui/material';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';

interface IUpload {
  link: string;
}

const UploadCv: React.FC<IUpload> = ({ link }) => {
  return (
    <BackgroundCard>
      <Typography className={styles.title}>Uploaded CV</Typography>
      <Button
        variant="contained"
        color="secondary"
        href={link}
        sx={{
          textTransform: 'capitalize',
          borderRadius: '0',
          width: '172px',
          height: '52px',
          color: '#171717',
          fontSize: '16px',
          fontWeight: '600',
          backgroundColor: '#DBDBDB'
        }}
      >
        PDF file
      </Button>
    </BackgroundCard>
  );
};

export default UploadCv;
