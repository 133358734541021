/* eslint-disable import/prefer-default-export */
// @mui icons

// pages
import Blog from 'pages/blog';
import BlogList from 'pages/blog/BlogList';
import CreateBlog from 'pages/blog/CreateBlog';
import UserForms from 'pages/user-forms';
import { JobPlacement, JobPlacementLayout } from 'pages/job-placement';
import JobPlacementRespond from 'pages/job-placement/job-placement-respond/JobPlacementRespond';
import {
  ROUTE_BLOG,
  ROUTE_CREATE_BLOG,
  ROUTE_JOB_PLACEMENT,
  ROUTE_USER_FORMS,
  ROUTE_COMPANIES
} from 'constants/routes';
import { UserRoles } from 'constants/user-roles';
import { Companies, CompaniesLayout } from 'pages/companies';
import CompaniesRespond from 'pages/companies/companies-respond/CompaniesRespond';

export const privateRoutes = [
  {
    name: 'Blog',
    key: ROUTE_BLOG,
    path: ROUTE_BLOG,
    canActivate: [UserRoles.BlogAdmin, UserRoles.SuperAdmin],
    element: <Blog />,
    children: [
      {
        index: true,
        element: <BlogList />
      },
      {
        path: ROUTE_CREATE_BLOG,
        element: <CreateBlog />,
        key: `${ROUTE_BLOG}/${ROUTE_CREATE_BLOG}`
      },
      {
        path: ':slug',
        element: <CreateBlog />,
        key: `${ROUTE_BLOG}/:slug`
      }
    ]
  },
  {
    name: 'User Forms',
    key: ROUTE_USER_FORMS,
    path: ROUTE_USER_FORMS,
    canActivate: [UserRoles.BusinessAdmin, UserRoles.SuperAdmin],
    element: <UserForms />
  },
  {
    name: 'Job Placement Requests ',
    key: ROUTE_JOB_PLACEMENT,
    path: ROUTE_JOB_PLACEMENT,
    element: <JobPlacementLayout />,
    canActivate: [UserRoles.JobPlacementAdmin, UserRoles.SuperAdmin],
    children: [
      {
        index: true,
        element: <JobPlacement />
      },
      {
        path: ':id',
        element: <JobPlacementRespond />
      }
    ]
  },
  {
    name: 'Added Companies',
    key: ROUTE_COMPANIES,
    path: ROUTE_COMPANIES,
    element: <CompaniesLayout />,
    canActivate: [UserRoles.BusinessAdmin, UserRoles.SuperAdmin],
    children: [
      {
        index: true,
        element: <Companies />
      },
      {
        path: ':slug',
        element: <CompaniesRespond />
      }
    ]
  }
];
