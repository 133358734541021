/* eslint-disable react/function-component-definition */
import { Box, Typography } from '@mui/material';
import React from 'react';

const ModalText: React.FC<{ fieldName: string; text: string }> = ({ fieldName, text }) => {
  return (
    <Box component="div" width="100%" marginBottom="8px">
      <Typography
        variant="body2"
        component="div"
        align="left"
        marginRight="8px"
        sx={{ textTransform: 'capitalize', float: 'left' }}
      >
        {fieldName}:
      </Typography>
      <Typography
        variant="body2"
        fontSize="16px"
        fontWeight={500}
        sx={{ textTransform: 'capitalize' }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ModalText;
