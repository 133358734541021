/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { IDAFileUpload } from 'interfaces';
import styles from './DAFileUpload.module.scss';

const Input = styled('input')({
  display: 'none'
});

function DAFileUpload({ text, startIcon, handleSelectFile }: IDAFileUpload) {
  return (
    <label htmlFor="contained-button-file">
      <Input
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event) => handleSelectFile(event)}
      />
      <Button
        className={clsx(styles.defaultButton, styles.containedButton)}
        variant="contained"
        component="span"
        startIcon={startIcon}
        classes={{ startIcon: styles.startIcon }}
      >
        {text}
      </Button>
    </label>
  );
}

export default DAFileUpload;
