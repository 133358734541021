/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
import { Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { IBlog } from 'interfaces/blog';
import React from 'react';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { ROUTE_BLOG } from 'constants/routes';
import styles from './Card.module.scss';
import editIcon from '../../../../assets/images/icons/card/edit.svg';
import deleteIcon from '../../../../assets/images/icons/card/delete.svg';

export const Card: React.FC<IBlog> = ({ image, short_description, slug, title, status }) => {
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  return (
    <div className={styles.cardWrapper}>
      {status === 'unpublished' && <span className={styles.status}>Draft</span>}
      <img className={styles.img} src={image} alt="company img" />
      <div className={styles.cardInner}>
        <div className={styles.description}>
          <Typography variant="h4" component="div" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="subtitle1" component="div" className={styles.text}>
            {short_description}
          </Typography>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.edit}
            color="secondary"
            onClick={() => navigate(`/${ROUTE_BLOG}/${slug}`)}
          >
            <img src={editIcon} alt="icon" />
            Edit
          </Button>
          <Button
            className={styles.delete}
            onClick={() => showModal(MODAL_TYPES.DELETE_BLOG_MODAL, { slug })}
          >
            <img src={deleteIcon} alt="icon" />
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
