/* eslint-disable react/function-component-definition */
import { Typography } from '@mui/material';
import { IJobPlacement } from 'interfaces/jobPlacement';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import RespondText from './RespondText';

interface ICulturalPreference {
  object: IJobPlacement;
}

const CulturalPreference: React.FC<ICulturalPreference> = ({ object }) => {
  return (
    <BackgroundCard>
      <Typography className={styles.title}>Cultural Preferences</Typography>
      <RespondText
        variant="culturalPreferences"
        title="As a woman when choosing an employer, what is important to you?"
        textArray={object.selected_choosing_employer_importance_options}
      />
      <div className={styles.line} />
      <RespondText
        variant="culturalPreferences"
        title="What motivates you to do the best job you possibly can?"
        textArray={object.selected_what_motivates_best_job_options}
      />
      <div className={styles.line} />
      <RespondText
        variant="culturalPreferences"
        title="What is a deal breaker for you?"
        textArray={object.selected_deal_breaker_options}
      />
    </BackgroundCard>
  );
};

export default CulturalPreference;
