/* eslint-disable react/no-danger */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Grid, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DAButton from 'components/common/DAButton';
import DAInput from 'components/common/DAInput';
import DAFileUpload from 'components/common/DAFileUpload';
import DAModal from 'components/common/DAModal';
import VisibilityModal from 'components/VisibilityModal';
import PublishDateModal from 'components/PublishDateModal';
import ImagePreviewModal from 'components/ImagePreviewModal';
import { capitalizeFirstLetter, formatDate, dataUrlToFile } from 'utils/helper';
import { uploadBlogImageAPI, addUpdateBlogApi, getBlogApi } from 'api/blog';
import { ROUTE_BLOG } from 'constants/routes';
import styles from './BlogList.module.scss';

const defaultValues = {
  title: '',
  author_name: '',
  source: ''
};

function CreateBlog() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { showModal } = useGlobalModalContext();

  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [cropper, setCropper] = useState<any>();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openVisibilitywModal, setOpenVisibilityModal] = useState(false);
  const [openPublishDateModal, setOpenPublishDateModal] = useState(false);
  const [openImagePreviewModal, setOpenImagePreviewModal] = useState(false);
  const [formValues, setFormValues] = useState(defaultValues);
  const [status, setStatus] = useState('unpublished');
  const [visibility, setVisibility] = useState('public');
  const [currentRadioButtonValue, setCurrentRadioButtonValue] = useState('public');
  const [publishDate, setPublishDate] = useState(new Date());
  const [createdDate, setCreatedDate] = useState(new Date());

  useEffect(() => {
    if (slug) {
      getBlogApi(slug)
        .then((res) => {
          if (res) {
            setFormValues({
              title: res.title,
              author_name: res.author_name ? res.author_name : '',
              source: res.source ? res.source : ''
            });
            setImageUrl(res.image);
            setContent(res.body ? res.body : '');
            setStatus(res.status ? res.status : '');
            setVisibility(res.visibility ? res.visibility : '');
            setCurrentRadioButtonValue(res.visibility ? res.visibility : '');
            setPublishDate(res.blogdate ? res.blogdate : new Date());
            setCreatedDate(res.created ? res.created : new Date());
          }
        })
        .catch((err) => {
          toast.error('Failed to get blog', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        });
    }
  }, [slug]);

  const handleSelectFile = async (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (!fileObj) {
      return;
    }

    setFileName(fileObj.name.split('.').shift());

    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImageUrl(reader.result as any);
      setOpenImagePreviewModal(true);
    };
    reader.readAsDataURL(fileObj);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleChangeVisibility = (event) => {
    setCurrentRadioButtonValue(event.target.value);
  };

  const handleCancelVisibility = () => {
    setCurrentRadioButtonValue(visibility);
    setOpenVisibilityModal(false);
  };

  const handleSaveVisibility = () => {
    if (slug) {
      const requestBody = {
        ...formValues,
        slug,
        body: content,
        image: imageUrl,
        status,
        visibility: currentRadioButtonValue,
        created: createdDate,
        blogdate: publishDate
      };
      addUpdateBlogApi(requestBody)
        .then((res) => {
          toast.success('Updated the blog visibility successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setVisibility(currentRadioButtonValue);
          setOpenVisibilityModal(false);
        })
        .catch((err) => {
          toast.error('Failed to update the blog visibility', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        });
    }

    setVisibility(currentRadioButtonValue);
    setOpenVisibilityModal(false);
  };

  const handleCancelPublishDate = () => {
    setOpenPublishDateModal(false);
  };

  const handleSavePublishDate = (date) => {
    if (slug) {
      const requestBody = {
        ...formValues,
        slug,
        body: content,
        image: imageUrl,
        status,
        visibility,
        created: createdDate,
        blogdate: date
      };
      addUpdateBlogApi(requestBody)
        .then((res) => {
          toast.success('Updated the blog publish date successfully', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setVisibility(currentRadioButtonValue);
          setOpenVisibilityModal(false);
        })
        .catch((err) => {
          toast.error('Failed to update the blog publish date', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        });
    }
    setPublishDate(date);
    setOpenPublishDateModal(false);
  };

  const handleSubmit = (blogStatus: string) => {
    const requestBody = {
      ...formValues,
      slug: slug || '',
      body: content,
      image: imageUrl,
      status: blogStatus,
      visibility,
      created: createdDate,
      blogdate: publishDate
    };
    addUpdateBlogApi(requestBody)
      .then((res) => {
        const successText = slug ? 'Updated successfully' : 'Created successfully';
        toast.success(successText, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        navigate(`/${ROUTE_BLOG}`);
      })
      .catch((err) => {
        const errorText = slug ? 'Failed to update the blog' : 'Failed to create a blog';
        toast.error(errorText, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  };

  const handleCloseImagePreviewModal = () => {
    setOpenImagePreviewModal(false);
  };

  const handleUploadBlogImage = async () => {
    if (typeof cropper !== 'undefined') {
      const cropData = cropper.getCroppedCanvas().toDataURL();
      if (cropData) {
        const file = dataUrlToFile(cropData, fileName);

        const formData = new FormData();
        formData.append('image', file);
        const response = await uploadBlogImageAPI(formData);
        setImageUrl(response?.direct_url);
        setOpenImagePreviewModal(false);
      }
    }
  };

  return (
    <Box className={styles.createBlogPage}>
      <Typography variant="h5" mb={3}>
        Add New Post
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box component="form">
            <FormControl fullWidth sx={{ marginBottom: '15px' }}>
              <DAInput
                name="title"
                label="Title"
                value={formValues.title}
                placeholder="Enter a title for this post"
                onInputChange={(event) => handleInputChange(event)}
              />
            </FormControl>
            <DAFileUpload
              text="Add Cover Image"
              startIcon={<AddIcon />}
              handleSelectFile={(event) => handleSelectFile(event)}
            />
            <Box mt={2} mb={3}>
              <JoditEditor
                ref={editor}
                value={content}
                config={{ placeholder: 'Start typings...', height: 450 }}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => console.log('new content:: ', newContent)}
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DAInput
                    name="author_name"
                    label="Author"
                    placeholder="Enter an author’s name"
                    value={formValues.author_name}
                    onInputChange={(event) => handleInputChange(event)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DAInput
                    name="source"
                    label="Source"
                    placeholder="Enter a source name"
                    value={formValues.source}
                    onInputChange={(event) => handleInputChange(event)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box className={styles.publishSection}>
            <Box className={styles.titleSection}>
              <Typography className={styles.publishTitle} variant="h5">
                Publish
              </Typography>
            </Box>
            <Box className={styles.contentSection}>
              <Box>
                <DAButton
                  text="Save Draft"
                  variant="outlined"
                  style={{ marginRight: '15px' }}
                  disabled={!!(slug && status === 'published')}
                  handleClick={() => handleSubmit('unpublished')}
                />
                <DAButton
                  text="Preview"
                  variant="outlined"
                  handleClick={() => setOpenPreviewModal(true)}
                />
              </Box>
              <Box mt={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={styles.label}>Status:</Typography>
                  <Typography className={styles.value}>{capitalizeFirstLetter(status)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={styles.label}>Visibility:</Typography>
                  <Typography className={styles.value}>
                    {capitalizeFirstLetter(visibility)}
                  </Typography>
                  <DAButton
                    text="Edit"
                    variant="text"
                    startIcon={<BorderColorOutlinedIcon style={{ fontSize: '16px' }} />}
                    style={{ padding: '8px 3px' }}
                    handleClick={() => setOpenVisibilityModal(true)}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={styles.label}>Publish Date:</Typography>
                  <Typography className={styles.value}>
                    {publishDate ? formatDate(publishDate) : 'Now'}
                  </Typography>
                  <DAButton
                    text="Edit"
                    variant="text"
                    startIcon={<BorderColorOutlinedIcon style={{ fontSize: '16px' }} />}
                    style={{ padding: '8px 3px' }}
                    handleClick={() => setOpenPublishDateModal(true)}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={styles.actionsSection}>
              {slug ? (
                <DAButton
                  text="Delete"
                  variant="text"
                  startIcon={<DeleteOutlineIcon style={{ fontSize: '16px' }} />}
                  style={{ color: '#FF5555', padding: '0px' }}
                  handleClick={() => showModal(MODAL_TYPES.DELETE_BLOG_MODAL, { slug })}
                />
              ) : (
                <div />
              )}

              <DAButton
                text="Publish"
                variant="contained"
                handleClick={() => handleSubmit('published')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DAModal
        title="Preview"
        open={openPreviewModal}
        handleClose={() => setOpenPreviewModal(false)}
        footer={null}
        styles={styles.previewModalContainer}
        divider
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </DAModal>
      <VisibilityModal
        visible={openVisibilitywModal}
        handleCancel={handleCancelVisibility}
        handleOk={handleSaveVisibility}
        value={currentRadioButtonValue}
        handleChange={(event) => handleChangeVisibility(event)}
      />
      <PublishDateModal
        visible={openPublishDateModal}
        handleCancel={handleCancelPublishDate}
        handleOk={(newDate) => handleSavePublishDate(newDate)}
      />
      <ImagePreviewModal
        visible={openImagePreviewModal}
        image={previewImageUrl}
        handleCancel={handleCloseImagePreviewModal}
        handleOk={handleUploadBlogImage}
        setCropper={setCropper}
      />
      <ToastContainer />
    </Box>
  );
}

export default CreateBlog;
