import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './UserForm.module.scss';
import data, { DataType } from '../job-placement/data';
import UserFormItem from './user-form-item/UserFormItem';

function UserForms() {
  return (
    <Box sx={{ flexGrow: 1, color: 'var(--color-primary-black)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography>All ({data.length})</Typography>
      </Box>
      {data.map((item: DataType) => (
        <Grid
          key={item.id}
          container
          textAlign="center"
          alignItems="center"
          className={styles.wrapper}
        >
          <UserFormItem item={item} />
        </Grid>
      ))}
      <ReactPaginate
        nextLabel=""
        className="pagination"
        pageClassName="page"
        pageRangeDisplayed={0}
        marginPagesDisplayed={2}
        initialPage={0}
        pageCount={3}
        previousLabel=""
      />
    </Box>
  );
}

export default UserForms;
