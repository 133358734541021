import { useState } from 'react';
import { Typography, Box, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useAppContext } from 'contexts';

import avatarImage from 'assets/images/avatar.jpg';
import useAuth from 'contexts/authContext';
import styles from './Header.module.scss';

function Header() {
  const { currentPage, setSearchValue } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const { isLogin, logout, user } = useAuth();
  const open = Boolean(anchorEl);

  const handlerLogout = () => {
    logout();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyEvent = (event) => {
    if (event.key === 'Enter') {
      setSearchValue(searchKey);
      event.preventDefault();
    }
  };

  const handleChangeSearchValue = (event) => {
    setSearchKey(event.target.value);
  };
  return (
    <div className={styles.headerContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={styles.pageTitle} variant="h5">
          {currentPage}
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <FormControl sx={{ width: '250px' }}>
            <OutlinedInput
              classes={{ root: styles.searchInput, notchedOutline: styles.borderNone }}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: '#838383', fontSize: '16px' }} />
                </InputAdornment>
              }
              value={searchKey}
              onChange={(event) => handleChangeSearchValue(event)}
              onKeyDown={(e) => handleKeyEvent(e)}
            />
          </FormControl>
        </Box>
      </div>
      {isLogin && (
        <div>
          <Box onClick={handleClick}>
            <Typography className={styles.userName} component="span">
              {user?.email}
            </Typography>
            <IconButton
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 36, height: 36 }} src={avatarImage} />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handlerLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
}

export default Header;
