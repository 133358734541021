/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';

type AppContextType = {
  setCurrentPage: (name: string) => void;
  setSearchValue: (value: string) => void;
  currentPage: string;
  searchValue: string;
};

const initialState: AppContextType = {
  setCurrentPage: () => {},
  setSearchValue: () => {},
  currentPage: '',
  searchValue: ''
};

export const AppContext = createContext(initialState);
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [currentPage, setCurrentPage] = useState('Blog');
  const [searchValue, setSearchValue] = useState('');
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ currentPage, setCurrentPage, searchValue, setSearchValue }}>
      {children}
    </AppContext.Provider>
  );
}
