import { IUser } from './user';

/* eslint-disable no-shadow */
export enum CompaniesStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  NotReviewed = 'Not Reviewed'
}

export type CompanyStatus =
  | typeof CompaniesStatus.Approved
  | typeof CompaniesStatus.Rejected
  | typeof CompaniesStatus.NotReviewed;

export interface ICompanies {
  created: Date;
  slug: string;
  name: string;
  logo: string;
  website: string;
  rating: number;
  short_description: string;
  city_or_zip_code: string;
  state: string;
  user: IUser[];
  ratingDate: Date;
  status: CompaniesStatus;
}

export interface ICompaniesDetail {
  address: string;
  country: string;
  created: Date;
  employees_count: number;
  flexible_hours: number;
  how_likely_to_recommend_company: number;
  is_recommended: boolean;
  job_sharing: number;
  least_like_top_three: string[];
  logo: string;
  most_like_top_three: string[];
  motherhood_supports: string[];
  name: string;
  paid_leave: number;
  part_time_opportunity: number;
  rate_equal_opportunities_to_move_up_organization: number;
  rate_equal_pay_for_equal_performance: number;
  rate_equal_treatment_of_men_and_women: number;
  rate_sponsorship_mentorship_opportunity: number;
  rating: number;
  ratingDate: Date;
  review_count: number;
  sector: string;
  short_description: string;
  slug: string;
  state: string;
  unpaid_leave: number;
  website: string;
  working_remotely: number;
  user: IUser[];
  city_or_zip_code: string;
  email: string;
  phone_number: number;
  status: CompaniesStatus;
}

export interface ICompaniesRespondBody {
  name: string;
  country: string;
  website: string;
  email: string;
  phone_number: number;
  logo: string;
  city_or_zip_code: string;
  sector: string;
  employees_count: number;
  short_description: string;
}
