/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';

interface IText {
  title: string;
  text?: string | string[];
  textArray?: string[];
  marginBottom?: string;
  variant: 'information' | 'jobPreferences' | 'culturalPreferences';
}

const RespondText: React.FC<IText> = ({ title, text, marginBottom = '0', variant, textArray }) => {
  switch (variant) {
    case 'information':
      return (
        <Box className={styles.element} sx={{ marginBottom: `${marginBottom}px` }}>
          <Typography className={styles.blockText} fontWeight={600}>
            {title}
          </Typography>
          {Array.isArray(text) ? (
            <Box className={styles.element}>
              {text.map((item, index) => (
                <Typography key={index} className={styles.blockText} color="#838383">
                  {item}
                </Typography>
              ))}
            </Box>
          ) : (
            <Typography className={styles.blockText} color="#838383">
              {text}
            </Typography>
          )}
        </Box>
      );
    case 'jobPreferences':
      return (
        <Box sx={{ marginBottom: `${marginBottom}px` }}>
          <Typography className={styles.blockText} fontWeight={600} marginBottom="16px">
            {title}
          </Typography>
          {Array.isArray(text) ? (
            <Box className={styles.element}>
              {text.map((item, index) => (
                <Typography key={index} className={styles.blockText} color="#838383">
                  {item}
                </Typography>
              ))}
            </Box>
          ) : (
            <Typography className={styles.blockText} color="#838383">
              {text}
            </Typography>
          )}
        </Box>
      );
    case 'culturalPreferences':
      return (
        <Box sx={{ marginBottom: `${marginBottom}px` }}>
          <Typography className={styles.blockText} fontWeight={600} marginBottom="16px">
            {title}
          </Typography>
          <Box className={styles.element}>
            {textArray &&
              textArray.map((item, index) => (
                <Typography key={index} className={styles.arrayText}>
                  {item}
                </Typography>
              ))}
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

export default RespondText;
