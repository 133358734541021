/* eslint-disable no-shadow */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import { Typography } from '@mui/material';
import React from 'react';

const ModalTitle: React.FC<{ title: string; align?: 'center' | 'left' | 'right' }> = ({
  title,
  align
}) => {
  return (
    <Typography
      align={align ? align : 'left'}
      variant="body2"
      component="div"
      width="100%"
      fontSize={18}
      fontWeight={600}
      marginBottom="24px"
    >
      {title}
    </Typography>
  );
};

export default ModalTitle;
