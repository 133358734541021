import React, { useRef } from 'react';
import { Box, Typography } from '@mui/material';
import Cropper from 'react-cropper';
import DAModal from 'components/common/DAModal';
import DAButton from 'components/common/DAButton';

import 'cropperjs/dist/cropper.css';
import styles from './ImagePreview.module.scss';

function ImagePreviewModal({ visible, image, setCropper, handleCancel, handleOk }) {
  const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setCropper(cropper);
  };

  return (
    <DAModal
      open={visible}
      handleClose={handleCancel}
      styles={styles.imagePreviewContainer}
      footer={null}
    >
      <Box sx={{ padding: '15px' }}>
        <Cropper
          ref={cropperRef}
          style={{ height: 400, width: '100%' }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          crop={onCrop}
          guides
        />
      </Box>

      <Box sx={{ padding: '15px' }}>
        <Typography>Preview Cover Image</Typography>
        <div
          className="img-preview"
          style={{ width: '100%', height: '300px', overflow: 'hidden' }}
        />
      </Box>
      <Box className={styles.previewActionButtons}>
        <DAButton
          text="Cancel"
          variant="outlined"
          handleClick={handleCancel}
          style={{ width: '120px', marginRight: '7px' }}
        />
        <DAButton
          text="Upload"
          variant="contained"
          style={{ width: '120px', marginLeft: '7px' }}
          handleClick={handleOk}
        />
      </Box>
    </DAModal>
  );
}

export default ImagePreviewModal;
