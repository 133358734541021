import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import ReactPaginate from 'react-paginate';
import { Pagination } from 'interfaces/pagination';
import { getParams } from 'utils/http.utils';
import { getCompaniesApi } from 'api';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ICompanies } from 'interfaces/companies';
import { useAppContext } from 'contexts';
import styles from './Companies.module.scss';
import CompaniesItem from './companies-item/CompaniesItem';

function Companies() {
  const { searchValue } = useAppContext();
  const itemsPerPage = 12;
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);
  const [pageCount, setPageCount] = useState(pagination.total || 0);
  const [companies, setCompanies] = useState<ICompanies[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [sort, setSort] = useState('');

  const onSortHandler = (e: SelectChangeEvent) => {
    setSort(e.target.value);
  };

  const getCompanies = async (params: Pick<Pagination, 'page' | 'size'>, searchString = '') => {
    try {
      const response = await getCompaniesApi(params, searchString);
      if (response) {
        setCompanies(response.items);
        setPagination({ page: response.page, size: response.size, total: response.total });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const params = getParams();
  useEffect(() => {
    const page = parseInt(params.page, 10) || 1;
    getCompanies({ page, size: 9 }, searchValue);
  }, [params.page, searchValue]);

  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / itemsPerPage));
  }, [pagination.page, itemsPerPage]);

  const handlePageClick = (event: any) => {
    const page = event.selected + 1;

    if (page !== pagination.page) {
      setSearchParams({ ...params, page });
      navigate(
        {
          search: createSearchParams({ ...params, page }).toString()
        },
        { replace: true }
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1, color: 'var(--color-primary-black)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography>All ({pagination.total})</Typography>
        <FormControl sx={{ border: 'none', width: '112px', fontSize: '18px' }}>
          <InputLabel id="sort-label" sx={{ alignItems: 'center' }}>
            Sort by
          </InputLabel>
          <Select
            id="sort-select"
            variant="standard"
            value={sort}
            onChange={onSortHandler}
            label="Sort by"
            className={styles.select}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {companies.length > 0 &&
        companies.map((item: ICompanies) => (
          <Grid
            key={item.name}
            container
            textAlign="center"
            alignItems="center"
            className={styles.wrapper}
          >
            <CompaniesItem item={item} />
          </Grid>
        ))}
      {pagination.total > 9 && (
        <ReactPaginate
          nextLabel=""
          className="pagination"
          pageClassName="page"
          onPageChange={handlePageClick}
          pageRangeDisplayed={0}
          marginPagesDisplayed={2}
          initialPage={pagination.page && pagination.page > 0 ? pagination.page - 1 : 0}
          pageCount={Number.isNaN(pageCount) ? 0 : pageCount}
          previousLabel=""
        />
      )}
    </Box>
  );
}

export default Companies;
