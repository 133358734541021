import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio } from '@mui/material';
import DAModal from 'components/common/DAModal';
import DAButton from 'components/common/DAButton';

import styles from './VisibilityModal.module.scss';

function VisibilityModal({ visible, handleCancel, handleOk, value, handleChange }) {
  return (
    <DAModal
      open={visible}
      handleClose={handleCancel}
      styles={styles.visibilityModalContainer}
      footer={null}
    >
      <Box component="form">
        <FormControl>
          <FormLabel id="visibility-radio-buttons-group-label" className={styles.radioGroupLabel}>
            Visibility:
          </FormLabel>
          <RadioGroup
            aria-labelledby="visibility-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              classes={{ label: styles.radioButtonLabel }}
              value="public"
              control={<Radio color="warning" />}
              label="Public"
            />
            <FormControlLabel
              classes={{ label: styles.radioButtonLabel }}
              value="user"
              control={<Radio color="warning" />}
              label="User"
            />
          </RadioGroup>
        </FormControl>
        <Box className={styles.visiblityActionButtons}>
          <DAButton
            text="Cancel"
            variant="outlined"
            handleClick={handleCancel}
            style={{ width: '120px', marginRight: '7px' }}
          />
          <DAButton
            text="Save"
            variant="contained"
            style={{ width: '120px', marginLeft: '7px' }}
            handleClick={handleOk}
          />
        </Box>
      </Box>
    </DAModal>
  );
}

export default VisibilityModal;
