/* eslint-disable react/function-component-definition */
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import LoginForm from 'components/loginForm/LoginForm';
import React from 'react';

const LoginModal: React.FC = () => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible setVisible={hideModal}>
      <LoginForm />
    </ModalWindow>
  );
};

export default LoginModal;
