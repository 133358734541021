import React, { useEffect, useState } from 'react';
// react-router components
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
// @mui material components
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// theme
import theme from 'theme';

// routes
import Sidenav from 'components/Sidenav';
import useAuth, { AuthProvider } from 'contexts/authContext';
import { GlobalModal, MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { privateRoutes } from 'routes';

import 'App.scss';
import AppLayout from 'layouts/AppLayout/indext';
import { UserRoles } from 'constants/user-roles';
import AuthHelpers from 'utils/AuthHelpers';
import RoleGuard from 'guards/role-guard';

export function getRoutes() {
  return privateRoutes
    .map((route) => {
      return {
        ...route,
        canActivate:
          RoleGuard((route.canActivate as UserRoles[]) || []) ||
          AuthHelpers.getUserInfo()?.is_superuser
      };
    })
    .filter((route) => route.canActivate);
}

function AppRoutes() {
  const { isLogin } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    if (!isLogin) {
      showModal(MODAL_TYPES.SIGN_IN_MODAL);
      navigate('/');
    }
  }, [isLogin, pathname]);

  return isLogin ? useRoutes(getRoutes()) : useRoutes([]);
}

function App() {
  const { pathname } = useLocation();
  const [menuRoutes, setMenuRoutes] = useState<any>([]);

  useEffect(() => {
    if (AuthHelpers.getUserInfo()) {
      setMenuRoutes(getRoutes());
    } else {
      setMenuRoutes([]);
    }
  }, [pathname]);

  return (
    <AuthProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalModal>
            <Sidenav routes={menuRoutes} />
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </GlobalModal>
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  );
}

export default App;
