/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { DataType } from '../../job-placement/data';

interface IItem {
  item: DataType;
}

const UserFormItem: React.FC<IItem> = ({ item }) => {
  const { email } = item;
  const { showModal } = useGlobalModalContext();
  const [answer, setAnswer] = useState<string>('');
  const [isReply, setIsReply] = useState<boolean>(false);

  const onReplyHandler = (text: string) => {
    setAnswer(text);
    setIsReply(true);
  };

  return (
    <>
      <Grid item xs={3} display="flex" alignItems="center">
        <Avatar>{item.avatar}</Avatar>
        <Typography variant="body2" fontWeight={500} marginLeft="12px">
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" fontWeight={500}>
          {item.email}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" fontWeight={500} textTransform="capitalize">
          subject
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" fontWeight={500}>
          {item.date} 16:47
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color={isReply ? 'secondary' : 'primary'}
          size="large"
          sx={{ textTransform: 'capitalize', marginRight: '12px', fontWeight: 700 }}
          onClick={() =>
            showModal(MODAL_TYPES.USER_FORM_RESPONSE_MODAL, {
              email,
              onReplyHandler,
              answer,
              isReply
            })
          }
        >
          answer
        </Button>
      </Grid>
    </>
  );
};

export default UserFormItem;
