import { ICompanies, ICompaniesDetail } from 'interfaces/companies';
import { Pagination } from 'interfaces/pagination';
import axios from '../utils/axios.config';

export interface ICompaniesRes extends Pagination {
  items: ICompanies[];
}

export const getCompaniesApi = (
  p: Pick<Pagination, 'page' | 'size'>,
  search = ''
): Promise<ICompaniesRes> => {
  return axios.get(
    `/api/admin/businesses?page=${p.page}&size=${p.size}${
      search.length ? `&search_term=${search}` : ''
    }`
  );
};

export const getCompaniesByIdApi = (slug: string): Promise<ICompaniesDetail> => {
  return axios.get(`/api/business/${slug}`);
};

export const patchCompanyApi = (body: ICompanies): Promise<string> => {
  return axios.patch('/api/business', body);
};

export const getIndustriesApi = (): Promise<string[]> => {
  return axios.get(`/api/industries`);
};

export const getCountriesApi = (): Promise<string[]> => {
  return axios.get(`/api/countries`);
};

export interface IUploadLogo {
  logo: string;
}

export const uploadCompanyLogoApi = (formData): Promise<{ direct_url: string }> => {
  return axios.post(`/api/business_logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
