/* eslint-disable react/function-component-definition */
import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface IProps {
  children: ReactNode;
}

const BackgroundCard: React.FC<IProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.08)',
        borderRadius: '16px',
        padding: '24px 32px',
        marginBottom: '20px'
      }}
    >
      {children}
    </Box>
  );
};

export default BackgroundCard;
