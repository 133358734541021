/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import DAButton from 'components/common/DAButton';
import AddIcon from '@mui/icons-material/Add';
import { ROUTE_BLOG, ROUTE_CREATE_BLOG } from 'constants/routes';
import { blogsApi } from 'api/blog';
import { getParams } from 'utils/http.utils';
import { useAppContext } from 'contexts';
import { IBlog } from 'interfaces/blog';
import { Pagination } from 'interfaces/pagination';
import Cards from './cards/Cards';
import styles from './BlogList.module.scss';

function BlogList() {
  const { searchValue } = useAppContext();
  const [blogs, setBlogs] = useState<IBlog[] | []>([]);
  const [isBlogsLoading, setIsBlogsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<Pagination>();

  const location = useLocation();

  const getBlogs = (params) => {
    setIsBlogsLoading(true);
    blogsApi(params)
      .then((res) => {
        setBlogs(res.items);
        setPagination({ total: res.total, page: res.page, size: res.size });
      })
      .finally(() => setIsBlogsLoading(false));
  };

  useEffect(() => {
    const params = getParams();
    const page = parseInt(params.page, 10) || 1;

    setBlogs([]);
    getBlogs({ page, size: 9, searchValue });
  }, [location, searchValue]);

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={styles.blogListCount}>All ({pagination?.total})</Typography>
        <DAButton
          text="Add Post"
          variant="contained"
          startIcon={<AddIcon />}
          href={`/${ROUTE_BLOG}/${ROUTE_CREATE_BLOG}`}
        />
      </Box>
      <Cards cards={blogs} button={null} pagination={pagination} isLoading={isBlogsLoading} />
    </div>
  );
}

export default BlogList;
