/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export interface FormInputProps {
  name: string;
  control: any;
  setValue?: any;
  placeholder: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  isMarginBottom?: boolean;
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  control,
  placeholder,
  type,
  multiline,
  rows,
  required,
  isMarginBottom
}) => {
  return (
    <div>
      {type !== 'file' ? (
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                error={!!error}
                onChange={onChange}
                value={value}
                sx={{
                  width: 450,
                  marginBottom: `${isMarginBottom ? '16px' : '0px'}`
                }}
                variant="outlined"
                placeholder={placeholder}
                type={type}
                multiline={multiline}
                rows={rows}
              />
              {!!error && (
                <Typography variant="body2" color="red">
                  Field is required.
                </Typography>
              )}
            </Box>
          )}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <input
              type="file"
              onChange={(e) => {
                field.onChange(e.target.files);
              }}
              hidden
            />
          )}
        />
      )}
    </div>
  );
};
