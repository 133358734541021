import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import useAuth from 'contexts/authContext';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import style from './LoginForm.module.scss';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { error, login, isLoading, isLogin } = useAuth();
  const { hideModal } = useGlobalModalContext();

  const onLoginHandler = async () => {
    if (email === '') {
      setEmailError(true);
    } else if (password === '') {
      setPasswordError(true);
    } else {
      try {
        await login(email, password);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      hideModal();
    }
  }, [isLogin]);

  return (
    <Box component="form" autoComplete="off" className={style.wrapper}>
      <div className={style.title}>
        <Typography variant="h3" gutterBottom component="div" color="inherit">
          Sign In
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div" color="inherit">
          Please fill in all the fields below to sign
        </Typography>
      </div>
      <div className={style.inputs}>
        {error && (
          <Typography variant="subtitle2" gutterBottom component="div" color="red">
            {error}
          </Typography>
        )}
        <TextField
          label="email"
          type="email"
          variant="outlined"
          error={emailError}
          helperText={emailError ? 'require field' : ''}
          color="secondary"
          className={style.input}
          value={email}
          onChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
        />
        <TextField
          label="password"
          type="password"
          error={passwordError}
          helperText={passwordError ? 'require field' : ''}
          variant="outlined"
          color="secondary"
          className={style.input}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
          }}
        />
      </div>
      <div className={style.button}>
        <Button variant="contained" color="inherit" onClick={onLoginHandler} disabled={isLoading}>
          {isLoading ? <AutorenewIcon /> : 'Sign in'}
        </Button>
      </div>
    </Box>
  );
}

export default Login;
