/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { CompaniesStatus, ICompanies } from 'interfaces/companies';
import { formatDate } from 'utils/helper';
import { useNavigate } from 'react-router-dom';
import { getUserData } from 'utils/getUserData.utils';

interface IItem {
  item: ICompanies;
}

const CompaniesItem: React.FC<IItem> = ({ item }) => {
  const { showModal } = useGlobalModalContext();
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={2} display="flex" alignItems="center">
        <Avatar src={getUserData(item.user, 'avatar_link', '')}>
          {item.user.length > 0 ? item.user[0].username[0] : null}
        </Avatar>
        <Typography variant="body2" fontWeight={500} marginLeft="12px">
          {getUserData(item.user, 'username', 'unnamed')}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" fontWeight={500}>
          {getUserData(item.user, 'email', 'not email')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="body2"
          textTransform="capitalize"
          border="1px solid"
          borderRadius="100px"
          padding="4px 10px"
          color={
            item.status === CompaniesStatus.Approved
              ? 'var(--color-green-recommended)'
              : 'var(--color-red-job-placement)'
          }
        >
          {item.status}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" fontWeight={500} textTransform="capitalize">
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" fontWeight={500}>
          {formatDate(item.created)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          size="large"
          sx={{ textTransform: 'capitalize', marginRight: '12px', fontWeight: 700 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(item.slug);
          }}
        >
          View
        </Button>
        <Button
          color="secondary"
          sx={{
            border: '1px solid #838383',
            borderRadius: '8px',
            padding: '7px',
            minWidth: '38px'
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            showModal(MODAL_TYPES.JOB_PLACEMENT_DELETE_MODAL);
          }}
        >
          <DeleteOutlinedIcon />
        </Button>
      </Grid>
    </>
  );
};

export default CompaniesItem;
