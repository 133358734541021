/* eslint-disable react/function-component-definition */
import { Box, Button, TextField, Typography } from '@mui/material';
import { ModalText, ModalTitle } from 'components/modals/components';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import React, { useState } from 'react';

const UserFormResponseModal: React.FC = () => {
  const { hideModal, showModal, store } = useGlobalModalContext();
  const { email, isReply, answer, onReplyHandler } = store.modalProps;
  const [value, setValue] = useState('');

  const onHandler = (text: string) => {
    try {
      onReplyHandler(text);
      hideModal();
      showModal(MODAL_TYPES.USER_FORM_SUCCESSES_MODAL);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ModalWindow visible setVisible={hideModal}>
      <ModalTitle title="User Form #25677" />
      <ModalText fieldName="form" text={email} />
      <ModalText fieldName="subject" text="subject" />
      <ModalText
        fieldName="description"
        text="sixth biggest French city (270,000) and the second biggest Hungarian city (200,000). There are 150,000 Poles and, a decade since the big EU bang, many of them have grown up here. "
      />
      {!isReply ? (
        <>
          <Box
            component="div"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            margin="12px 0"
          >
            <Typography
              variant="body2"
              fontSize={12}
              fontWeight={600}
              marginBottom="8px"
              sx={{ textTransform: 'capitalize' }}
            >
              reply
            </Typography>
            <TextField
              value={value}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Enter your reply"
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setValue(event.target.value);
              }}
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              marginTop: '8px',
              padding: '12px 20px',
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: '16px',
              marginLeft: 'auto'
            }}
            onClick={() => onHandler(value)}
          >
            reply
          </Button>
        </>
      ) : (
        <ModalText fieldName="reply" text={answer} />
      )}
    </ModalWindow>
  );
};

export default UserFormResponseModal;
