/* eslint-disable react/function-component-definition */
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { jobPlacementPatchApi } from 'api/jobplacement';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { PlacementStatus } from 'interfaces/jobPlacement';
import React, { useState } from 'react';
import { ModalTitle } from './components';

const JobPlacementChangeStatusModal: React.FC = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { item, setJobPlacement } = store.modalProps;
  const [status, setStatus] = useState(item.current_employment_status);

  const saveStatusHandler = async () => {
    const newItem = { ...item, current_employment_status: status };
    try {
      await jobPlacementPatchApi(newItem, item.id);
      setJobPlacement(newItem);
      hideModal();
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatusHandler = (value: string) => {
    setStatus(value);
  };

  return (
    <ModalWindow visible setVisible={hideModal}>
      <ModalTitle title="Change the Status:" />
      <Box
        component="div"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        display="flex"
        width="100%"
        marginBottom="24px"
      >
        <Box display="flex" alignItems="center" fontSize={16} color="var(--color-primary-black)">
          <Checkbox
            checked={status === PlacementStatus.Full_time}
            onChange={() => changeStatusHandler(PlacementStatus.Full_time)}
          />
          <Typography>{PlacementStatus.Full_time}</Typography>
        </Box>
        <Box display="flex" alignItems="center" fontSize={16} color="var(--color-primary-black)">
          <Checkbox
            checked={status === PlacementStatus.Part_time}
            onChange={() => changeStatusHandler(PlacementStatus.Part_time)}
          />
          <Typography>{PlacementStatus.Part_time}</Typography>
        </Box>
        <Box display="flex" alignItems="center" fontSize={16} color="var(--color-primary-black)">
          <Checkbox
            checked={status === PlacementStatus.Unemployed}
            onChange={() => changeStatusHandler(PlacementStatus.Unemployed)}
          />
          <Typography>{PlacementStatus.Unemployed}</Typography>
        </Box>
      </Box>
      <Box
        component="div"
        alignItems="center"
        justifyContent="flex-end"
        display="flex"
        width="100%"
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            padding: '12px 20px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={hideModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '12px 44px',
            marginLeft: '16px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={saveStatusHandler}
        >
          Save
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default JobPlacementChangeStatusModal;
