import { IUser } from './user';

/* eslint-disable no-shadow */
export enum PlacementStatus {
  Full_time = 'Full-time',
  Part_time = 'Part-time',
  Unemployed = 'Unemployed'
}

export enum JobOpportunityType {
  Full_time = 'Full-time',
  Part_time = 'Part-time',
  Internship = 'Internship',
  No_Preference = 'No Preference'
}

export enum WorkPreference {
  Remote = 'Remote',
  In_person = 'In-person',
  Hybrid = 'Hybrid',
  No_Preference = 'No Preference'
}
export interface IJobPlacement {
  id: string;
  user_id: string;
  created: Date;
  modified: string;
  current_employment_status: PlacementStatus;
  current_job_title: string;
  current_job_category: string;
  current_country: string;
  current_zip_code_or_city: string;
  selected_job_opportunity_type: JobOpportunityType[];
  selected_work_preference: WorkPreference[];
  selected_job_title: string;
  selected_job_category: string;
  selected_work_flexibility;
  selected_commute_options;
  selected_travel_options;
  selected_choosing_employer_importance_options;
  selected_what_motivates_best_job_options;
  selected_deal_breaker_options;
  notification_new_companies_are_rated: boolean;
  notification_latest_women_news: boolean;
  notification_receive_job_alerts: boolean;
  cv_link: string;
  user: IUser[];
}
