import React from 'react';

// react-router-dom components
import { NavLink } from 'react-router-dom';

// @mui material components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppContext } from 'contexts';
import Logo from 'assets/images/logo.svg';
import useAuth from 'contexts/authContext';

import styles from './Sidenav.module.scss';

function Sidenav({ routes }) {
  const { setCurrentPage } = useAppContext();
  const { isLogin } = useAuth();
  return (
    <div className={styles.sideNavContainer}>
      <div className={styles.appLogo}>
        <img src={Logo} alt="Logo" />
      </div>
      {isLogin && (
        <List className={styles.p0}>
          {routes.map(({ name, icon, key, path }) => (
            <NavLink
              key={key}
              to={path}
              className={({ isActive }) => (isActive ? styles.activeMenuItem : undefined)}
            >
              <ListItem
                className={styles.menuItem}
                component="li"
                onClick={() => setCurrentPage(name)}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText classes={{ primary: styles.menuItemText }} primary={name} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      )}
    </div>
  );
}

export default Sidenav;
