import React from 'react';
import Header from 'components/Header';

import styles from './AppLayout.module.scss';

function AppLayout({ children }) {
  return (
    <div className={styles.appLayoutContainer}>
      <Header />
      <div className={styles.appLayoutContent}>{children}</div>
    </div>
  );
}

export default AppLayout;
