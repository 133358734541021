import {
  IBlog,
  IBlogsRes,
  IBlogDelete,
  IAddUpdateBlogRequest,
  ICreateBlogResponse,
  IBlogImage
} from 'interfaces/blog';
import { Pagination } from '../interfaces/pagination';
import axios from '../utils/axios.config';

export const blogsApi = (param, highlighted = false): Promise<IBlogsRes> => {
  if (highlighted) {
    return axios.get(
      `/api/admin/blogs?page=${param.page}&size=${param.size}&highlighted=true&search_term=${param.searchValue}`
    );
  }

  return axios.get(
    `/api/admin/blogs?page=${param.page}&size=${param.size}&search_term=${param.searchValue}`
  );
};

export const relatedBlogsApi = (
  slug: string,
  p: Pick<Pagination, 'page' | 'size'> = { page: 1, size: 3 }
): Promise<IBlogsRes> => {
  return axios.get(`/api/blog/${slug}/related?page=${p.page}&size=${p.size}`);
};

export const getBlogApi = (slug: string): Promise<IBlog> => {
  return axios.get(`/api/blog/${slug}`);
};

export const deleteBlogApi = (slug: string): Promise<IBlogDelete> => {
  return axios.delete(`/api/admin/blog?slug=${slug}`);
};

export const addUpdateBlogApi = (body: IAddUpdateBlogRequest): Promise<ICreateBlogResponse> => {
  return axios.put('/api/admin/blog', body);
};

export const uploadBlogImageAPI = (body: any): Promise<IBlogImage> => {
  return axios.post('/api/admin/blog_image', body);
};
