/* eslint-disable react/function-component-definition */
import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IJobPlacement } from 'interfaces/jobPlacement';
import { getJobPlacementByIdApi } from 'api/jobplacement';
import styles from './JobPlacementRespond.module.scss';
import RespondAbout from '../components/RespondAbout';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import JobGeneralInfo from '../components/JobGeneralInfo';
import JobPreference from '../components/JobPreference';
import CulturalPreference from '../components/CulturalPreference';
import UploadCv from '../components/UploadCv';

const JobPlacementRespond: React.FC = () => {
  const { id } = useParams();
  const [value, setValue] = useState('');
  const [object, setObject] = useState({} as IJobPlacement);

  useEffect(() => {
    if (id) {
      getJobPlacementByIdApi(id).then((res) => setObject(res));
    }
  }, [id]);

  const onHandler = (text: string) => {
    alert(`no implement ${text}`);
  };

  return (
    <>
      <Typography fontSize={20} fontWeight={600} sx={{ color: '#343A40', marginBottom: '24px' }}>
        Job Placement Respond #{id}
      </Typography>
      <RespondAbout item={object} />
      <JobGeneralInfo object={object} />
      <JobPreference object={object} />
      <CulturalPreference object={object} />
      {object.cv_link && <UploadCv link={object.cv_link} />}
      <BackgroundCard>
        <Typography className={styles.title}>Respond</Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Your Reply:</Typography>
        <TextField
          value={value}
          variant="outlined"
          fullWidth
          multiline
          rows={6}
          placeholder="Enter your reply"
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(event.target.value);
          }}
        />
        <Button
          variant="contained"
          sx={{
            marginTop: '20px',
            padding: '12px 20px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px',
            marginLeft: 'auto',
            width: '120px'
          }}
          onClick={() => onHandler(value)}
        >
          reply
        </Button>
      </BackgroundCard>
    </>
  );
};

export default JobPlacementRespond;
