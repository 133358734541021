/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ICompaniesDetail, ICompaniesRespondBody } from 'interfaces/companies';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { getCountriesApi, getIndustriesApi, patchCompanyApi, uploadCompanyLogoApi } from 'api';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import styles from '../companies-respond/CompaniesRespond.module.scss';
import { CustomFormControl } from './CustomFormControl';
import { FormInput } from './FormInput';
import { FormSelector } from './FormSelector';

interface IRespondAbout {
  item: ICompaniesDetail;
}

const UsersInformation: React.FC<IRespondAbout> = ({ item }) => {
  const [countries, setCountries] = useState<string[]>([]);
  const [sectors, setSectors] = useState<string[]>([]);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [defaultVal, setDefaultVal] = useState<ICompaniesRespondBody>({
    name: '',
    country: '',
    city_or_zip_code: '',
    website: '',
    email: '',
    phone_number: Number(''),
    logo: '',
    sector: '',
    employees_count: Number(''),
    short_description: ''
  });
  const [selectCountry, setSelectCountry] = useState('');

  const getSelectors = async () => {
    try {
      const selectorCountries = await getCountriesApi();
      const selectorSectors = await getIndustriesApi();
      setCountries(selectorCountries);
      setSectors(selectorSectors);
    } catch (e) {
      setCountries(['not found']);
    }
  };

  const { handleSubmit, control, watch, getValues, setValue, reset } =
    useForm<ICompaniesRespondBody>({
      defaultValues: defaultVal
    });

  useEffect(() => {
    getSelectors();
    setDefaultVal((prev) => {
      return {
        ...prev,
        name: item.name ? item.name : '',
        country: item.country === 'US' ? 'United States' : item.country,
        city_or_zip_code: item.city_or_zip_code ? item.city_or_zip_code : '',
        website: item.website ? item.website : '',
        email: item.email ? item.email : '',
        phone_number: item.phone_number ? item.phone_number : 0,
        logo: item.logo ? item.logo : '',
        sector: item.sector ? item.sector : '',
        employees_count: item.employees_count ? item.employees_count : 0,
        short_description: item.short_description ? item.short_description : ''
      };
    });
    reset(defaultVal);
  }, [item]);

  const onSubmit = async () => {
    setIsSaveLoading(true);
    try {
      if (getValues('logo') && getValues('logo') !== item.logo) {
        const formData = new FormData();
        formData.append('logo', getValues('logo')[0]);
        const logUrl = await uploadCompanyLogoApi(formData);
        setValue('logo', logUrl.direct_url);
      }
      const newItem = { ...item, ...getValues() };
      await patchCompanyApi(newItem);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaveLoading(false);
    }
  };

  useEffect(() => {
    setSelectCountry(getValues('country'));
  }, [watch('country')]);

  return (
    <BackgroundCard>
      <Typography className={styles.title}>User’s Information</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '240px' }}>
          <CustomFormControl label="Company name:">
            <FormInput control={control} name="name" placeholder="Company name" />
          </CustomFormControl>
          <CustomFormControl label="Country:">
            <FormSelector
              name="country"
              control={control}
              placeholder="Choose a country where your company is located"
              items={countries}
            />
          </CustomFormControl>

          <CustomFormControl label={selectCountry === 'United States' ? 'Zip Code:' : 'City:'}>
            <FormInput
              control={control}
              name="city_or_zip_code"
              placeholder={
                selectCountry === 'United States'
                  ? 'Enter a zip code where your company is located'
                  : 'Enter a city where your company is located'
              }
            />
          </CustomFormControl>
          <CustomFormControl label="Website:">
            <FormInput control={control} name="website" placeholder="Enter a link to website" />
          </CustomFormControl>
          <CustomFormControl label="Contacts:" align="start">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormInput
                control={control}
                name="email"
                type="email"
                placeholder="Enter an e-mail address"
                isMarginBottom
              />
              <FormInput
                control={control}
                name="phone_number"
                type="number"
                placeholder="Enter a phone number"
              />
            </Box>
          </CustomFormControl>
          <CustomFormControl label="Company Logo:">
            <Button
              sx={{
                display: 'flex',
                padding: '12px 20px',
                textTransform: 'capitalize',
                fontWeight: 700,
                fontSize: '14px'
              }}
              component="label"
              variant="contained"
            >
              <AddIcon />
              Upload Company Logo
              <FormInput control={control} type="file" name="logo" placeholder="" />
            </Button>
          </CustomFormControl>
          <CustomFormControl label="Sector:">
            <FormSelector
              name="sector"
              control={control}
              placeholder="Select industry sector of your company"
              items={sectors}
            />
          </CustomFormControl>
          <CustomFormControl label="Number of Employees:">
            <FormInput
              control={control}
              name="employees_count"
              type="number"
              placeholder="Enter a number of employees"
            />
          </CustomFormControl>
          <CustomFormControl label="Company description:">
            <FormInput
              control={control}
              name="short_description"
              multiline
              rows={5}
              placeholder="Enter a Company description"
            />
          </CustomFormControl>
          <Button
            disabled={isSaveLoading}
            variant="contained"
            sx={{
              display: 'block',
              marginTop: '20px',
              padding: '12px 64px',
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: '16px',
              marginLeft: 'auto',
              backgroundColor: 'var(--color-primary-yellow)'
            }}
            type="submit"
          >
            save
          </Button>
        </Box>
      </form>
    </BackgroundCard>
  );
};

export default UsersInformation;
