/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};

export const formatDate = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const dataUrlToFile = (url: string, fileName: string) => {
  const byteString = atob(url.split(',')[1]);
  const mime = url.split(',')[0].split(':')[1].split(';')[0];
  const extension = mime?.split('/').pop();

  const arrayBuffer = new ArrayBuffer(byteString.length);

  const unit8Array = new Uint8Array(arrayBuffer);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    unit8Array[i] = byteString.charCodeAt(i);
  }

  return new File([unit8Array], `${fileName}.${extension}`, {
    type: mime
  });
};

export const validateHhMm = (type, value) => {
  const hoursRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3])$/;
  const minutesRegex = /^[0-5][0-9]$/;

  if (type === 'hour') {
    return !hoursRegex.test(value);
  }
  if (type === 'minute') {
    return !minutesRegex.test(value);
  }
  return true;
};
