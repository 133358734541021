import { createTheme } from '@mui/material/styles';
import colors from './colors';
import breakpoints from './breakpoints';
import typography from './typography';

export default createTheme({
  breakpoints: { ...breakpoints },
  palette: { ...colors },
  typography: { ...typography }
});
