/* eslint-disable no-shadow */
export enum UserRoles {
  BlogAdmin = 'Blog Admin',
  BusinessAdmin = 'Business Admin',
  JobPlacementAdmin = 'Job Placement Admin',
  SuperAdmin = 'Super Admin'
}

export type UserRole =
  | typeof UserRoles.BlogAdmin
  | typeof UserRoles.BusinessAdmin
  | typeof UserRoles.JobPlacementAdmin
  | typeof UserRoles.SuperAdmin;
