import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import ReactPaginate from 'react-paginate';
import { getJobPlacementsApi } from 'api';
import { Pagination } from 'interfaces/pagination';
import { getParams } from 'utils/http.utils';
import { IJobPlacement } from 'interfaces/jobPlacement';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './JobPlacement.module.scss';
import JobPlacementItem from './job-placement-item/JobPlacementItem';

function JobPlacement() {
  const itemsPerPage = 9;
  const [pagination, setPagination] = useState<Pagination>({} as Pagination);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [sort, setSort] = useState('');

  const [jobPlacement, setJobPlacement] = useState<IJobPlacement[]>([]);

  const onSortHandler = (e: SelectChangeEvent) => {
    setSort(e.target.value);
  };

  const getJobPlacement = async (params: Pick<Pagination, 'page' | 'size'>) => {
    try {
      const response = await getJobPlacementsApi(params);
      if (response) {
        setJobPlacement(response.items);
        setPagination({ page: response.page, size: response.size, total: response.total });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const params = getParams();
  useEffect(() => {
    const page = parseInt(params.page, 10) || 1;
    getJobPlacement({ page, size: 9 });
  }, [params.page]);

  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / itemsPerPage));
  }, [pagination.page, itemsPerPage]);

  const handlePageClick = (event: any) => {
    const page = event.selected + 1;

    if (page !== pagination.page) {
      setSearchParams({ ...params, page });
      navigate(
        {
          search: createSearchParams({ ...params, page }).toString()
        },
        { replace: true }
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1, color: 'var(--color-primary-black)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography>All ({pagination.total})</Typography>
        <FormControl sx={{ border: 'none', width: '112px', fontSize: '18px' }}>
          <InputLabel id="sort-label" sx={{ alignItems: 'center' }}>
            Sort by
          </InputLabel>
          <Select
            id="sort-select"
            variant="standard"
            value={sort}
            onChange={onSortHandler}
            label="Sort by"
            className={styles.select}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {jobPlacement.length > 0 &&
        jobPlacement.map((item: IJobPlacement) => (
          <Grid
            key={item.id}
            container
            textAlign="center"
            alignItems="center"
            className={styles.wrapper}
          >
            <JobPlacementItem item={item} />
          </Grid>
        ))}
      {pagination.total > 9 && (
        <ReactPaginate
          nextLabel=""
          className="pagination"
          pageClassName="page"
          onPageChange={handlePageClick}
          pageRangeDisplayed={0}
          marginPagesDisplayed={2}
          initialPage={0}
          pageCount={pageCount}
          previousLabel=""
        />
      )}
    </Box>
  );
}

export default JobPlacement;
