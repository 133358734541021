import { IJobPlacement } from 'interfaces/jobPlacement';
import { Pagination } from '../interfaces/pagination';
import axios from '../utils/axios.config';

export interface IJobPlacementsRes extends Pagination {
  items: IJobPlacement[];
}

export const getJobPlacementsApi = (
  p: Pick<Pagination, 'page' | 'size'>,
  highlighted = false
): Promise<IJobPlacementsRes> => {
  if (highlighted) {
    return axios.get(`/api/admin/jobPlacements?page=${p.page}&size=${p.size}&highlighted=true`);
  }

  return axios.get(`/api/admin/jobPlacements?page=${p.page}&size=${p.size}`);
};

export interface IJobPlacementRes {
  items: IJobPlacement;
}

export const getJobPlacementByIdApi = (placementId: string): Promise<IJobPlacement> => {
  return axios.get(`/api/jobPlacement/by_id/${placementId}`);
};

interface IJobPlacementDelete {
  message: string;
}

export const jobPlacementDeleteApi = (id: string): Promise<IJobPlacementDelete> => {
  return axios.delete(`/api/admin/jobPlacement/?id=${id}`);
};
interface IJobPlacementPatch {
  message: string;
}

export const jobPlacementPatchApi = (
  body: IJobPlacement,
  id: string
): Promise<IJobPlacementPatch> => {
  return axios.patch(`/api/admin/jobPlacement/`, { ...body, id });
};
