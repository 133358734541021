/* eslint-disable react/function-component-definition */
import { FormControl, FormLabel } from '@mui/material';
import { ReactNode } from 'react';

/* eslint-disable import/prefer-default-export */
interface ICustomFormControl {
  label: string;
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  align?: string;
}

export const CustomFormControl: React.FC<ICustomFormControl> = ({ label, children, align }) => {
  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: `${align ? { align } : 'center'}`,
        marginBottom: '16px'
      }}
    >
      <FormLabel sx={{ color: '#171717', fontWeight: '600' }}>{label}</FormLabel>
      {children}
    </FormControl>
  );
};
