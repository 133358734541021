/* eslint-disable react/function-component-definition */
import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { formatDate } from 'utils/helper';
import { getUserData } from 'utils/getUserData.utils';
import { IJobPlacement, PlacementStatus } from 'interfaces/jobPlacement';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';

interface IRespondAbout {
  item: IJobPlacement;
}

const RespondAbout: React.FC<IRespondAbout> = ({ item }) => {
  const { id } = useParams();
  const { showModal } = useGlobalModalContext();
  const [jobPlacement, setJobPlacement] = useState<IJobPlacement>({} as IJobPlacement);

  useEffect(() => {
    if (item) {
      setJobPlacement(item);
    }
  }, [item]);

  return (
    <BackgroundCard>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        {jobPlacement.user && (
          <>
            <Avatar
              src={getUserData(jobPlacement.user, 'avatar_link', '')}
              sx={{ marginRight: '20px', width: '56px', height: '56px' }}
            >
              {getUserData(jobPlacement.user, 'username', 'unnamed')[0]}
            </Avatar>
            <Box>
              <Typography className={styles.aboutText} fontWeight={500} textTransform="capitalize">
                {getUserData(jobPlacement.user, 'username', 'unnamed')}
              </Typography>
              <Typography className={styles.aboutText} color="#838383">
                {getUserData(jobPlacement.user, 'email', 'not email')}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          status:
        </Typography>
        <Typography
          variant="body2"
          textTransform="capitalize"
          border="1px solid"
          borderRadius="100px"
          padding="4px 10px"
          color={
            jobPlacement.current_employment_status !== PlacementStatus.Unemployed
              ? 'var(--color-green-recommended)'
              : 'var(--color-red-job-placement)'
          }
          className={styles.aboutText}
        >
          {jobPlacement.current_employment_status}
        </Typography>
        <Button
          sx={{ textTransform: 'capitalize', color: '#FFA425' }}
          onClick={() => {
            showModal(MODAL_TYPES.JOB_PLACEMENT_CHANGE_STATUS_MODAL, {
              item: jobPlacement,
              setJobPlacement
            });
          }}
        >
          <BorderColorOutlinedIcon fontSize="small" />
          <Typography
            className={styles.aboutText}
            sx={{ borderBottom: '2px solid', lineHeight: '16px' }}
          >
            Change status
          </Typography>
        </Button>
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          job category:
        </Typography>
        <Typography color="#838383" className={styles.aboutText}>
          {item.current_job_category}
        </Typography>
      </Box>
      <Box className={styles.element} sx={{ marginBottom: '20px' }}>
        <Typography fontWeight={500} className={styles.aboutText} textTransform="capitalize">
          request date:
        </Typography>
        {item.created && (
          <Typography color="#838383" className={styles.aboutText}>
            {formatDate(item.created)}
          </Typography>
        )}
      </Box>
      <Box className={styles.element}>
        <Button
          variant="contained"
          size="large"
          sx={{ textTransform: 'capitalize', marginRight: '12px', fontWeight: 700 }}
          onClick={() => {
            alert('no implement');
          }}
        >
          Respond
        </Button>
        <Button
          color="secondary"
          sx={{
            border: '1px solid #838383',
            borderRadius: '8px',
            padding: '7px',
            minWidth: '38px'
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            showModal(MODAL_TYPES.JOB_PLACEMENT_DELETE_MODAL, { id, email: item.user[0].email });
          }}
        >
          <DeleteOutlinedIcon />
        </Button>
      </Box>
    </BackgroundCard>
  );
};

export default RespondAbout;
