/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import useAuth from 'contexts/authContext';
import React, { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useEffect } from 'react';
import style from './ModalWindow.module.scss';

interface ModalWindowProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  children?: ReactNode;
}

export const ModalWindow: React.FC<ModalWindowProps> = ({
  visible = false,
  setVisible,
  children
}) => {
  useEffect(() => {
    window.document.getElementsByTagName('body')[0].setAttribute('class', 'show-modal');
    return () => {
      window.document.getElementsByTagName('body')[0].removeAttribute('class');
    };
  }, []);
  const rootClasses = [style.modal];
  if (visible) {
    rootClasses.push(style.active);
  }
  const { isLogin } = useAuth();

  const handleCloseModal = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLogin) {
      setVisible(false);
    }
  };

  return (
    <div className={rootClasses.join(' ')} onClick={handleCloseModal}>
      <div className={style.content} onClick={(e) => e.stopPropagation()}>
        <button className={style.close} onClick={handleCloseModal} />
        {children}
      </div>
    </div>
  );
};

export default ModalWindow;
