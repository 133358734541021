/* eslint-disable react/function-component-definition */
import { Box, Button, Typography } from '@mui/material';
import { jobPlacementDeleteApi } from 'api/jobplacement';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import React from 'react';
import { ModalTitle } from './components';

const JobPlacementDeleteModal: React.FC = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { email, id } = store.modalProps;

  const deleteHandler = async () => {
    try {
      await jobPlacementDeleteApi(id);
      hideModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ModalWindow visible setVisible={hideModal}>
      <ModalTitle title="Are you sure you want to delete this request?" />
      <Box
        component="div"
        alignItems="center"
        justifyContent="flex-start"
        display="flex"
        width="100%"
        marginBottom="24px"
      >
        <Typography variant="body2" component="div" align="center" marginRight="8px">
          From:
        </Typography>
        <Typography variant="h5" align="center" fontSize="16px" fontWeight={500}>
          {email}
        </Typography>
      </Box>
      <Box
        component="div"
        alignItems="center"
        justifyContent="flex-end"
        display="flex"
        width="100%"
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            padding: '12px 20px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={hideModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '12px 20px',
            marginLeft: '16px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={deleteHandler}
        >
          Yes, Delete
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default JobPlacementDeleteModal;
