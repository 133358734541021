/* eslint-disable react/function-component-definition */
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { patchCompanyApi } from 'api';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { CompaniesStatus } from 'interfaces/companies';
import React, { useState } from 'react';
import { ModalTitle } from './components';

const CompanyChangeStatusModal: React.FC = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { item, setCompanies } = store.modalProps;
  const [status, setStatus] = useState(item.status);

  const saveStatusHandler = async () => {
    const newItem = { ...item, status };
    try {
      await patchCompanyApi(newItem);
      setCompanies(newItem);
      hideModal();
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatusHandler = (value: string) => {
    setStatus(value);
  };

  return (
    <ModalWindow visible setVisible={hideModal}>
      <ModalTitle title="Change the Status:" />
      <Box
        component="div"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        display="flex"
        width="100%"
        marginBottom="24px"
      >
        <Box display="flex" alignItems="center" fontSize={16} color="var(--color-primary-black)">
          <Checkbox
            checked={status === CompaniesStatus.Approved}
            onChange={() => changeStatusHandler(CompaniesStatus.Approved)}
          />
          <Typography>{CompaniesStatus.Approved}</Typography>
        </Box>
        <Box display="flex" alignItems="center" fontSize={16} color="var(--color-primary-black)">
          <Checkbox
            checked={status === CompaniesStatus.Rejected}
            onChange={() => changeStatusHandler(CompaniesStatus.Rejected)}
          />
          <Typography>{CompaniesStatus.Rejected}</Typography>
        </Box>
      </Box>
      <Box
        component="div"
        alignItems="center"
        justifyContent="flex-end"
        display="flex"
        width="100%"
      >
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            padding: '12px 20px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={hideModal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '12px 44px',
            marginLeft: '16px',
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '16px'
          }}
          onClick={saveStatusHandler}
        >
          Save
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default CompanyChangeStatusModal;
