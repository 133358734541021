import { UserRole } from 'constants/user-roles';
import AuthHelper from 'utils/AuthHelpers';

export const RoleGuard = (roles: UserRole[]): boolean => {
  return (
    AuthHelper.getUserInfo() &&
    AuthHelper.getUserInfo().roles.some((role) => {
      return roles.includes(role);
    })
  );
};

export default RoleGuard;
