import React, { FC, useState, createContext, useContext, ReactNode } from 'react';
import LoginModal from 'components/common/modal/LoginModal';
import {
  CompanyChangeStatusModal,
  DeleteBlogModal,
  JobPlacementChangeStatus,
  JobPlacementDeleteModal,
  UserFormResponseModal,
  UserFormSuccessesModal
} from 'components/modals';

export const MODAL_TYPES = {
  SIGN_IN_MODAL: 'SIGN_IN_MODAL',
  DELETE_BLOG_MODAL: 'DELETE_BLOG_MODAL',
  JOB_PLACEMENT_DELETE_MODAL: 'JOB_PLACEMENT_DELETE_MODAL',
  JOB_PLACEMENT_CHANGE_STATUS_MODAL: 'JOB_PLACEMENT_CHANGE_STATUS_MODAL',
  USER_FORM_RESPONSE_MODAL: 'USER_FORM_RESPONSE_MODAL',
  USER_FORM_SUCCESSES_MODAL: 'USER_FORM_SUCCESSES_MODAL',
  COMPANY_CHANGE_STATUS: 'COMPANY_CHANGE_STATUS'
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.SIGN_IN_MODAL]: LoginModal,
  [MODAL_TYPES.DELETE_BLOG_MODAL]: DeleteBlogModal,
  [MODAL_TYPES.JOB_PLACEMENT_DELETE_MODAL]: JobPlacementDeleteModal,
  [MODAL_TYPES.USER_FORM_RESPONSE_MODAL]: UserFormResponseModal,
  [MODAL_TYPES.USER_FORM_SUCCESSES_MODAL]: UserFormSuccessesModal,
  [MODAL_TYPES.JOB_PLACEMENT_CHANGE_STATUS_MODAL]: JobPlacementChangeStatus,
  [MODAL_TYPES.COMPANY_CHANGE_STATUS]: CompanyChangeStatusModal
};

type GlobalModalContext = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

/* eslint-disable */
const initialState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  store: {}
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: FC<{ children: ReactNode }> = ({ children }) => {
  const [store, setStore] = useState({});
  // @ts-ignore
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
/* eslint-enable */
