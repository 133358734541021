/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import React, { useState } from 'react';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { IJobPlacement, PlacementStatus } from 'interfaces/jobPlacement';
import { formatDate } from 'utils/helper';
import { IUser } from 'interfaces/user';
import { Link } from 'react-router-dom';
import styles from './JobPlacement.module.scss';

interface IItem {
  item: IJobPlacement;
}

const JobPlacementItem: React.FC<IItem> = ({ item }) => {
  const { id, created, user, current_job_category, current_employment_status } = item;
  const [currentUser, setCurrentUser] = useState<IUser>({ ...user[0] } as IUser);
  const { showModal } = useGlobalModalContext();
  const { email, username } = currentUser;

  return (
    <Link to={item.id} className={styles.wrapper}>
      <Grid item xs={1} display="flex" alignItems="center">
        <Avatar>{username[0]}</Avatar>
        <Typography variant="body2" fontWeight={500} marginLeft="12px">
          {username === '' ? 'unnamed' : username}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" fontWeight={500}>
          {email}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant="body2"
          textTransform="capitalize"
          border="1px solid"
          borderRadius="100px"
          padding="4px 10px"
          color={
            current_employment_status !== PlacementStatus.Unemployed
              ? 'var(--color-green-recommended)'
              : 'var(--color-red-job-placement)'
          }
        >
          {current_employment_status}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" fontWeight={500} textTransform="capitalize">
          {current_job_category}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" fontWeight={500}>
          {formatDate(created)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          size="large"
          sx={{ textTransform: 'capitalize', marginRight: '12px', fontWeight: 700 }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            alert('no implement');
          }}
        >
          Respond
        </Button>
        <Button
          color="secondary"
          sx={{
            border: '1px solid #838383',
            borderRadius: '8px',
            padding: '7px',
            minWidth: '38px'
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            showModal(MODAL_TYPES.JOB_PLACEMENT_DELETE_MODAL, { email, id });
          }}
        >
          <DeleteOutlinedIcon />
        </Button>
      </Grid>
    </Link>
  );
};

export default JobPlacementItem;
