/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import styles from '../companies-respond/CompaniesRespond.module.scss';
import { CustomFormControl } from './CustomFormControl';
import { FormInput } from './FormInput';

const AdditionalInformation: React.FC = () => {
  const fields = [
    { title: 'Field_1', name: 'field1' },
    { title: 'Field_2', name: 'field2' },
    { title: 'Field_3', name: 'field3' },
    { title: 'Field_4', name: 'field4' },
    { title: 'Field_5', name: 'field5' }
  ];

  const defaultValues = {};

  fields.forEach((item) => {
    defaultValues[item.name] = '';
  });

  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  return (
    <BackgroundCard>
      <Typography className={styles.title}>Additional Information</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '240px' }}>
          {fields.map((item) => {
            return (
              <CustomFormControl label={item.title} key={item.name}>
                <FormInput control={control} name={item.name} placeholder="Enter" />
              </CustomFormControl>
            );
          })}

          <Button
            variant="contained"
            sx={{
              display: 'block',
              marginTop: '20px',
              padding: '12px 64px',
              textTransform: 'capitalize',
              fontWeight: 700,
              fontSize: '16px',
              marginLeft: 'auto',
              backgroundColor: 'var(--color-primary-yellow)'
            }}
            type="submit"
          >
            save
          </Button>
        </Box>
      </form>
    </BackgroundCard>
  );
};

export default AdditionalInformation;
