/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export interface FormInputProps {
  name: string;
  control: any;
  setValue?: any;
  placeholder: string;
  items?: any[];
}

export const FormSelector: React.FC<FormInputProps> = ({ name, control, placeholder, items }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          error={!!error}
          onChange={onChange}
          value={value}
          sx={{ width: 450 }}
          variant="outlined"
          displayEmpty
        >
          {items?.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      )}
    />
  );
};
