import axios from 'axios';
import AuthHelpers from './AuthHelpers';

export const baseURL = process.env.REACT_APP_API_BASEURL || 'https://backend.diverseup.com';

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL
});

instance.interceptors.request.use(
  function (config: any) {
    const TOKEN = AuthHelpers.getAccessToken();
    if (TOKEN) {
      config.headers.Authorization = `Bearer ${TOKEN}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response?.data;
  },
  function (error) {
    if (error.status === 401) {
      AuthHelpers.clearStorage();
      window.location.href = '';
    }

    return Promise.reject(error.response);
  }
);

export default instance;
