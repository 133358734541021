/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getUserApi, getUserByIdApi, loginApi, logoutApi } from 'api';
import { IUser } from 'interfaces/user';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from 'App';
import { MODAL_TYPES, useGlobalModalContext } from './GlobalModalContext';
import AuthHelper from '../utils/AuthHelpers';

interface AuthContextType {
  isLogin: boolean;
  isLoading: boolean;
  error: string | null;
  user: IUser | null;
  login: (email: string, password: string) => void;
  logout: () => void;
}

type InitialType = {
  isLogin: boolean;
  isLoading: boolean;
  error: string | null;
  user: IUser | null;
};

export const AuthContext = createContext({} as AuthContextType);

const initialState: InitialType = {
  isLogin: !!AuthHelper.getAccessToken(),
  isLoading: false,
  error: null,
  user: null
};

export function AuthProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState(initialState);
  const navigate = useNavigate();
  const { showModal } = useGlobalModalContext();

  const isAuthorized = async () => {
    try {
      const user = await getUserApi();
      setState({ isLogin: true, error: null, isLoading: false, user });
    } catch (err: any) {
      setState({
        isLogin: false,
        error: err.data.detail,
        isLoading: false,
        user: null
      });
      showModal(MODAL_TYPES.SIGN_IN_MODAL);
    }
  };

  useEffect(() => {
    isAuthorized();
  }, []);

  const login = async (email: string, password: string) => {
    setState({ isLogin: false, error: null, isLoading: true, user: null });

    loginApi({ username: email, password })
      .then((response) => {
        AuthHelper.storeAccessToken(response.access_token);
        getUserApi().then((user: IUser) => {
          if ((user.roles && user.roles.length) || user.is_superuser) {
            AuthHelper.storeUserInfo(user);
            setState({ isLogin: true, error: null, isLoading: false, user });

            navigate(user.is_superuser ? '/blog' : `/${getRoutes()[0].path}`);
          } else {
            setState({
              isLogin: false,
              error: "You don't have permission for this application",
              isLoading: false,
              user: null
            });
            AuthHelper.clearStorage();
          }
        });
      })
      .catch((err) => {
        setState({
          isLogin: false,
          error: err.data.detail,
          isLoading: false,
          user: null
        });
      });
  };

  const logout = () => {
    logoutApi()
      .then(() => {
        setState({ isLogin: false, error: null, isLoading: false, user: null });
        AuthHelper.clearStorage();
        navigate('/');
        showModal(MODAL_TYPES.SIGN_IN_MODAL);
      })
      .catch(() => {
        setState({ isLogin: false, error: null, isLoading: false, user: null });
        AuthHelper.clearStorage();
        navigate('/');
        showModal(MODAL_TYPES.SIGN_IN_MODAL);
      });
  };

  const memoedValue = useMemo(
    () => ({
      ...state,
      login,
      logout
    }),
    [state, login, logout]
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return useContext(AuthContext);
}
