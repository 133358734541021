import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IDAModal } from 'interfaces';

function DAModal({ title, footer, open, handleClose, styles, divider, children }: IDAModal) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={!!divider}>{children}</DialogContent>
      {footer && <DialogActions sx={{ display: 'block' }}>{footer}</DialogActions>}
    </Dialog>
  );
}

export default DAModal;
