import { IUser } from 'interfaces/user';
import axios from '../utils/axios.config';

export interface ILoginBody {
  username: string;
  password: string;
}

export interface ILoginRes {
  access_token: string;
  token_type: string;
}

export const getUserApi = (): Promise<IUser> => {
  return axios.get('/api/users/me');
};

export const getUserByIdApi = (id: string): Promise<IUser> => {
  return axios.get(`/api/users/${id}`);
};

export const loginApi = (body: ILoginBody): Promise<ILoginRes> => {
  const form = new FormData();
  form.append('username', body.username);
  form.append('password', body.password);
  return axios.post('/api/auth/jwt/login', form, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export const logoutApi = () => {
  return axios.post('/api/auth/jwt/logout');
};
