/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import isWeekend from 'date-fns/isWeekend';
import DAModal from 'components/common/DAModal';
import DAButton from 'components/common/DAButton';
import DAInput from 'components/common/DAInput';
import { validateHhMm } from 'utils/helper';

import styles from './PublishDate.module.scss';

const defaultValues = {
  hour: '',
  minute: ''
};

function PublishDateModal({ visible, handleCancel, handleOk }) {
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const [formValues, setFormValues] = useState(defaultValues);

  const handleChangeDate = (newValue) => {
    setDateValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSaveDate = () => {
    if (
      dateValue &&
      !validateHhMm('hour', formValues.hour) &&
      !validateHhMm('minute', formValues.minute)
    ) {
      const newDate = new Date(
        dateValue.getFullYear(),
        dateValue.getMonth(),
        dateValue.getDate(),
        parseInt(formValues.hour, 10),
        parseInt(formValues.minute, 10),
        0
      );
      handleOk(newDate);
    }
  };

  return (
    <DAModal
      open={visible}
      handleClose={handleCancel}
      styles={styles.publishDateModalContainer}
      footer={null}
    >
      <Box component="form">
        <FormControl>
          <FormLabel className={styles.modalTitle}>Publish Date:</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker<Date>
              displayStaticWrapperAs="desktop"
              value={dateValue}
              shouldDisableDate={isWeekend}
              onChange={(newValue) => {
                handleChangeDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Box className={styles.timeSection}>
            <Typography variant="h5" className={styles.timeLabel}>
              Publish Time:{' '}
            </Typography>
            <FormControl sx={{ maxWidth: '60px', marginRight: '10px' }}>
              <DAInput
                name="hour"
                value={formValues.hour}
                error={validateHhMm('hour', formValues.hour)}
                onInputChange={(event) => handleInputChange(event)}
              />
            </FormControl>
            <Typography variant="h5" className={styles.timeLabel}>
              :
            </Typography>
            <FormControl sx={{ maxWidth: '60px', marginRight: '10px' }}>
              <DAInput
                name="minute"
                value={formValues.minute}
                error={validateHhMm('minute', formValues.minute)}
                onInputChange={(event) => handleInputChange(event)}
              />
            </FormControl>
          </Box>
        </FormControl>
        <Box className={styles.actionButtons}>
          <DAButton
            text="Cancel"
            variant="outlined"
            handleClick={handleCancel}
            style={{ width: '120px', marginRight: '7px' }}
          />
          <DAButton
            text="Save"
            variant="contained"
            style={{ width: '120px', marginLeft: '7px' }}
            handleClick={handleSaveDate}
          />
        </Box>
      </Box>
    </DAModal>
  );
}

export default PublishDateModal;
