/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { getParams } from 'utils/http.utils';
import ReactPaginate from 'react-paginate';
import { IBlog } from 'interfaces/blog';
import { Pagination } from 'interfaces/pagination';
import Loader from 'components/common/loader/Loader';
import { Card } from './card/Card';

import styles from './Cards.module.scss';

interface ICards {
  cards: IBlog[];
  isLoading: boolean;
  button: {
    text: string;
    onClick: () => void;
  } | null;
  pagination?: Pagination;
}

const Cards: React.FC<ICards> = ({ cards, button, pagination, isLoading }) => {
  const itemsPerPage = 12;
  const [pageCount, setPageCount] = useState(pagination?.total || 0);
  const [itemOffset, setItemOffset] = useState(pagination?.page || 0);
  const [currentItems, setCurrentItems] = useState(cards);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentItems(cards);
    setPageCount(pagination?.total ? Math.ceil(pagination?.total / itemsPerPage) : 0);
  }, [cards, pagination]);

  const handlePageClick = (event: any) => {
    const page = event.selected + 1;
    const params = getParams();

    if (page !== pagination?.page) {
      setSearchParams({ ...params, page });
      navigate(
        {
          search: createSearchParams({ ...params, page }).toString()
        },
        { replace: true }
      );
    }
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(cards.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(cards.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className={styles.cardWrapper}>
            {currentItems.map((item: IBlog, i: number) => (
              <Card
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                image={item.image}
                short_description={item.short_description}
                slug={item.slug}
                title={item.title}
                status={item.status}
              />
            ))}
          </div>
          {pagination && (
            <ReactPaginate
              nextLabel=""
              className="pagination"
              pageClassName="page"
              onPageChange={handlePageClick}
              pageRangeDisplayed={0}
              marginPagesDisplayed={2}
              initialPage={pagination?.page - 1 || 0}
              pageCount={pageCount}
              previousLabel=""
            />
          )}
        </div>
      )}
    </>
  );
};

export default Cards;
