/* eslint-disable react/function-component-definition */
import { Typography } from '@mui/material';
import { IJobPlacement } from 'interfaces/jobPlacement';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import RespondText from './RespondText';

interface IJobGeneralInfo {
  object: IJobPlacement;
}

const JobGeneralInfo: React.FC<IJobGeneralInfo> = ({ object }) => {
  return (
    <BackgroundCard>
      <Typography className={styles.title}>General Information</Typography>
      <RespondText
        variant="information"
        title="Current employment status:"
        text="Full time"
        marginBottom="16"
      />
      <div className={styles.line} />
      <RespondText
        variant="information"
        title="Job Title:"
        text={object.current_job_title}
        marginBottom="16"
      />
      <RespondText variant="information" title="Job Function :" text="Job Function" />
      <div className={styles.line} />
      <RespondText
        variant="information"
        title="Country:"
        text={object.current_country}
        marginBottom="16"
      />
      <RespondText
        variant="information"
        title={object.current_country === 'United States' ? 'Zip Code:' : 'City:'}
        text={object.current_zip_code_or_city}
      />
    </BackgroundCard>
  );
};

export default JobGeneralInfo;
