import React from 'react';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import { IDAInput } from 'interfaces';
import styles from './DAInput.module.scss';

function DAInput({ label, name, value, placeholder, error = false, onInputChange }: IDAInput) {
  return (
    <>
      <Typography variant="h5" className={styles.inputLabel}>
        {label}
      </Typography>
      <OutlinedInput
        name={name}
        classes={{ root: styles.outlinedInput, notchedOutline: error ? '' : styles.borderNone }}
        placeholder={placeholder}
        value={value}
        fullWidth
        error={error}
        onChange={(event) => onInputChange && onInputChange(event)}
      />
    </>
  );
}

export default DAInput;
