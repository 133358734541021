/* eslint-disable react/function-component-definition */
import { Typography } from '@mui/material';
import { IJobPlacement } from 'interfaces/jobPlacement';
import styles from '../job-placement-respond/JobPlacementRespond.module.scss';
import BackgroundCard from '../../../components/backgroundCard/BackgroundCard';
import RespondText from './RespondText';

interface IJobPreference {
  object: IJobPlacement;
}

const JobPreference: React.FC<IJobPreference> = ({ object }) => {
  return (
    <BackgroundCard>
      <Typography className={styles.title}>Job Preferences</Typography>
      <RespondText
        variant="jobPreferences"
        title="What kind of job opportunity are you looking for?"
        text="Full time"
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="What is your work preference?"
        text={object.selected_work_preference}
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="What is a job title you are looking for?"
        text={object.selected_job_title}
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="What is a job function you are interested in?"
        text="Job Function"
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="Which of the following work flexibility options are you interested in?"
        text={object.selected_work_flexibility}
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="How much commute are you willing to do for your job?"
        text={object.selected_commute_options}
      />
      <div className={styles.line} />
      <RespondText
        variant="jobPreferences"
        title="How much travel are you willing to do for your job?"
        text={object.selected_travel_options}
      />
    </BackgroundCard>
  );
};

export default JobPreference;
