/* eslint-disable react/function-component-definition */
import { Typography } from '@mui/material';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import React from 'react';
import successes from '../../assets/images/icons/successes.svg';

const UserFormSuccessesModal: React.FC = () => {
  const { hideModal } = useGlobalModalContext();

  return (
    <ModalWindow visible setVisible={hideModal}>
      <img src={successes} alt="asd" />
      <Typography variant="body2" component="div" fontSize={18} fontWeight={600} marginTop="24px">
        Your reply is sent!
      </Typography>
    </ModalWindow>
  );
};

export default UserFormSuccessesModal;
