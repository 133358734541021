/* eslint-disable react/function-component-definition */
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { deleteBlogApi } from 'api/blog';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import React from 'react';
import { ROUTE_BLOG } from 'constants/routes';
import { ModalTitle } from './components';

const DeleteBlogModal: React.FC = () => {
  const navigate = useNavigate();
  const { hideModal, store } = useGlobalModalContext();
  const { slug } = store.modalProps;

  const deleteHandler = () => {
    deleteBlogApi(slug)
      .then(() => {
        hideModal();
        navigate(`/${ROUTE_BLOG}`);
      })
      .catch(() => {
        hideModal();
      });
  };

  return (
    <ModalWindow visible setVisible={hideModal}>
      <ModalTitle title="Are you sure you want to delete this post?" align="center" />
      <Box
        component="div"
        alignItems="center"
        justifyContent="space-around"
        display="flex"
        width="100%"
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ opacity: 0.3, padding: '11px 41px', textTransform: 'capitalize' }}
          onClick={hideModal}
        >
          cancel
        </Button>
        <Button variant="contained" sx={{ padding: '11px 41px' }} onClick={deleteHandler}>
          Yes, Delete
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default DeleteBlogModal;
